import FAQ from '@/components/FAQ/FAQ.vue'

export default {
    name: 'FAQ-view',
    components: {
        FAQ
    },
    props: {
        faqs: Object
    }
}