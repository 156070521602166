export default {
    name: 'FAQ',
    props: {
        items: Array,
        color: String,
        showToggler: Boolean,
    },
    methods: {
        toggleVisibility: function (item) {
            item.showAnswer = !item.showAnswer
            this.$forceUpdate()
        }
    },
    created: function () {
        this.items.forEach(item => {
            item.showAnswer = false
        })
        console.log(this.items)
    }
}